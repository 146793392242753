import styled from 'styled-components'
import media from '../../utils/media'

import 'react-responsive-carousel/lib/styles/carousel.min.css'
import pointer from '../../assets/icons/info.svg'
import arrowLeft from '../../assets/icons/nav_arrow_left.svg'
import arrowRight from '../../assets/icons/nav_arrow_right.svg'
import { Carousel } from 'react-responsive-carousel'
import { Container } from '../Content/styles'

export const CarouselContainer = styled(Carousel)`
  /* Override carousel styles */
  height: 100%;
  width: 100%;
  ${media.lessThan('sm')`${props => (props.isHome ? 'height: 60%;' : '')};`};

  & > .carousel.carousel-slider {
    height: 100%;
    width: 100%;
    .slider-wrapper,
    ul,
    li {
      width: 100%;
      height: 100%;
    }
  }
  & > .carousel.carousel-slider button.control-arrow {
    opacity: 0 !important;
    padding: ${props => (!props.isHome ? '0 5%;' : '0 170px;')};
    ${media.between('sm', 'xl')`
      padding: ${props => (!props.isHome ? '0 5%;' : '0 70px;')};`};
    ${media.lessThan('sm')`
      padding: ${props => (!props.isHome ? '0 5%;' : '0 20px;')};`};

    width: 0 !important;
    &.control-prev:hover {
      cursor: url(${arrowLeft}), auto;
    }
    &.control-next:hover {
      cursor: url(${arrowRight}), auto;
    }
    &.control-prev::before,
    &.control-next::before {
      border-right: 0px !important;
      border-left: 0px !important;
    }
  }
`

export const HomePageSlide = styled.div`
  position: relative;
  width: 100%;
  padding: 0 320px;
  ${media.between('sm', 'xl')`padding: 0 120px;`};
  ${media.lessThan('sm')`padding: 0 35px;`};

  img {
    margin: 0;
  }

  a:hover {
    cursor: url(${pointer}), auto;
  }
`

export const ProjectSlide = styled.div`
  position: relative;
  width: 90%;
  left: 5%;
  height: 100%;
  /* Gatsby Img */
  .middle {
    top: 50%;

    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    @media screen and (min-aspect-ratio: 3/2) {
      width: auto !important;
      height: 100% !important;
    }
  }
  .middle img {
    @media screen and (min-aspect-ratio: 3/2) {
      width: auto !important;
      height: 100% !important;
      left: 50% !important;
      transform: translateX(-50%);
    }
  }
  /* mp4 */
  video {
    position: relative;
    display: block;
    width: 80%;
    height: auto;
    top: 50%;
    left: 10%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    @media screen and (min-aspect-ratio: 3/2) {
      width: auto !important;
      height: 100% !important;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }
  /* yt, vimeo */
  iframe {
    height: 100%;
  }
  /* gif */
  img[type='gif'] {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    @media screen and (min-aspect-ratio: 3/2) {
      width: auto !important;
      height: 100% !important;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }
`

export const TextContainer = styled(Container)`
  text-align: left;
  padding: 0;
  padding-right: 5%;
  min-height: 100%;
  display: flex;
  justify-content: center;
`

export const ProjectContainer = styled.div`
  height: calc(100% - 190px);
  width: 100%;
  position: absolute;
  top: 95px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
export const ProjectCaption = styled.div`
  width: 100%;
  position: relative;
  top: calc(95px - 23px);

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  & > p {
    padding: 10px 5%;
    font-size: 21px;
  }
`
