import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import {
  CarouselContainer,
  HomePageSlide,
  ProjectSlide,
  TextContainer
} from './styles'

export default ({
  projects,
  slides,
  html,
  handleSlideChange,
  selectedSlide,
  order
}) => {
  const getSlideType = slide => {
    if (slide.type === 'media_files' && slide.media_file) {
      const arr = slide.media_file.relativePath.split('.')
      const ext = arr[arr.length - 1]

      if (ext === 'mp4') {
        return (
          <video controls>
            <source
              src={`/img/${slide.media_file.relativePath}`}
              type='video/mp4'
            />
          </video>
        )
      } else if (ext === 'png' || ext === 'jpg' || ext === 'jpeg') {
        return (
          <Img
            className='middle'
            fluid={slide.media_file.childImageSharp.fluid}
          />
        )
      } else if (ext === 'gif') {
        return <img src={`/img/${slide.media_file.relativePath}`} type='gif' />
      }
    } else if (slide.type === 'yt_video') {
      const arr = slide.path.split('/')
      const id = arr[arr.length - 1]
      return <iframe src={`https://www.youtube.com/embed/${id}`} />
    } else if (slide.type === 'vimeo_video') {
      return <iframe src={slide.path} />
    } else if (slide.type === 'html') {
      return <TextContainer dangerouslySetInnerHTML={{ __html: html }} />
    }
  }

  return (
    <CarouselContainer
      showThumbs={false}
      showStatus={false}
      showIndicators={false}
      useKeyboardArrows
      infiniteLoop
      onChange={handleSlideChange}
      selectedItem={selectedSlide}
      isHome={projects}
    >
      {projects
        ? order.map(({ project }, i) => {
          const { node } = projects.edges.filter(
            ({ node }) => project === node.frontmatter.project_id
          )[0]
          return (
            <HomePageSlide key={i}>
              <Link to={node.fields.slug}>
                {node.frontmatter.image && (
                  <Img fluid={node.frontmatter.image.childImageSharp.fluid} />
                )}
              </Link>
            </HomePageSlide>
          )
        })
        : slides &&
          [...slides, { type: 'html', value: html }].map((slide, i) => (
            <ProjectSlide key={i}>{getSlideType(slide)}</ProjectSlide>
          ))}
    </CarouselContainer>
  )
}
